import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";
import customMarkerIcon from "../../assets/marker.png";
import style from "./Map.module.scss";
import { LocationPoint, LocationTime } from "../../assets/iconsLocation";

export function MapComponent() {
  const customIcon = new L.Icon({
    iconUrl: customMarkerIcon,
    iconSize: [19, 24.4],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });
  return (
    <MapContainer
      center={[44.77932, 17.20824]}
      zoom={14}
      style={{ height: "100%", width: "100%" }}
    >
      <TileLayer
        url="https://api.maptiler.com/maps/basic/256/{z}/{x}/{y}.png?key=xSW8KjQl4nwPfJCqOk98"
        attribution='&copy; <a href="https://www.maptiler.com/copyright/">MapTiler</a>'
      />
      <Marker
        position={[44.77935622159229, 17.208226919174198]}
        icon={customIcon}
      >
        <Popup>
          <p className={style.locationName}>Banja Luka</p>
          <p className={style.locationAddress}>
            <LocationPoint />
            <span>Bulevar srpske vojske 8, </span>
          </p>
          <p className={style.locationAddress}>
            <span>TC Delta Planet</span>
          </p>
          <p className={style.locationAddress}>
            <span>(ispod Cinestar balkona)</span>
          </p>
          <p className={style.locationInfo}>
            <LocationTime />
            <span>Svakog dana 00-24 h</span>
          </p>
        </Popup>
      </Marker>
      <Marker
        position={[44.77623755266946, 17.183625698089603]}
        icon={customIcon}
      >
        <Popup>
          <p className={style.locationName}>Banja Luka</p>
          <p className={style.locationAddress}>
            <LocationPoint />
            <span>Jovana Dučića 23 a</span>
          </p>
          <p className={style.locationInfo}>
            <LocationTime />
            <span>Svakog dana 00-24 h</span>
          </p>
        </Popup>
      </Marker>{" "}
      <Marker position={[44.76136, 17.19211]} icon={customIcon}>
        <Popup>
          <p className={style.locationName}>Banja Luka</p>
          <p className={style.locationAddress}>
            <LocationPoint />
            <span className={"ms-2 ps-1"}>Cara Lazara 31, Obilićevo</span>
          </p>
          <p className={style.locationAddress}>
            <span className={"ms-4 ps-1"}>Kod NES pumpe</span>
          </p>
          <p className={style.locationInfo}>
            <LocationTime />
            <span className={"ms-2"}>Svakog dana 00-24 h</span>
          </p>
        </Popup>
      </Marker>{" "}
      <Marker
        position={[44.772855948420776, 17.218220829963688]}
        icon={customIcon}
      >
        <Popup>
          <p className={style.locationName}>Banja Luka</p>
          <p className={style.locationAddress}>
            <LocationPoint />
            <span className={"ms-2 ps-1"}>
              Bulevar vojvode Živojina Mišića,{" "}
            </span>
          </p>
          <p className={style.locationAddress}>
            <span className={"ms-4 ps-1"}>Ada kod NES pumpe</span>
          </p>
          <p className={style.locationInfo}>
            <LocationTime />
            <span className={"ms-2"}>Svakog dana 00-24 h</span>
          </p>
        </Popup>
      </Marker>
      <Marker position={[44.773688, 17.197919]} icon={customIcon}>
        <Popup>
          <p className={style.locationName}>Banja Luka</p>
          <p className={style.locationAddress}>
            <LocationPoint />
            <span className={"ms-2 ps-1"}>Nikole Pašića 53, Borik </span>
          </p>
          <p className={style.locationAddress}>
            <span className={"ms-4 ps-1"}>kod pumpe Krajina Petrol</span>
          </p>
          <p className={style.locationInfo}>
            <LocationTime />
            <span className={"ms-2"}>Svakog dana 00-24 h</span>
          </p>
        </Popup>
      </Marker>
      <Marker position={[43.854193, 18.365217]} icon={customIcon}>
        <Popup>
          <p className={style.locationName}>Sarajevo</p>
          <p className={style.locationAddress}>
            <LocationPoint />
            <span className={"ms-2 ps-1"}>Adema Buće, Sarajevo</span>
          </p>
          <p className={style.locationAddress}>
            <span className={"ms-4 ps-1"}>kod pumpe Hifa Petrol</span>
          </p>
          <p className={style.locationInfo}>
            <LocationTime />
            <span className={"ms-2"}>Svakog dana 00-24 h</span>
          </p>
        </Popup>
      </Marker>
      <Marker position={[43.832673, 18.309134]} icon={customIcon}>
        <Popup>
          <p className={style.locationName}>Sarajevo</p>
          <p className={style.locationAddress}>
            <LocationPoint />
            <span className={"ms-2 ps-1"}>Rustem-pašina 38, Ilidža</span>
          </p>
          <p className={style.locationAddress}>
            <span className={"ms-4 ps-1"}>kod pumpe Hifa Petrol</span>
          </p>
          <p className={style.locationInfo}>
            <LocationTime />
            <span className={"ms-2"}>Svakog dana 00-24 h</span>
          </p>
        </Popup>
      </Marker>
      <Marker position={[43.894408, 18.371552]} icon={customIcon}>
        <Popup>
          <p className={style.locationName}>Sarajevo</p>
          <p className={style.locationAddress}>
            <LocationPoint />
            <span className={"ms-2 ps-1"}>Hotonj bb, Vogošća</span>
          </p>
          <p className={style.locationAddress}>
            <span className={"ms-4 ps-1"}>kod pumpe Hifa petrol</span>
          </p>
          <p className={style.locationInfo}>
            <LocationTime />
            <span className={"ms-2"}>Svakog dana 00-24 h</span>
          </p>
        </Popup>
      </Marker>
      <Marker
        position={[43.83899745066992, 18.276144954140587]}
        icon={customIcon}
      >
        <Popup>
          <p className={style.locationName}>Sarajevo</p>
          <p className={style.locationAddress}>
            <LocationPoint />
            <span className={"ms-2 ps-1"}>Osik</span>
          </p>
          <p className={style.locationAddress}>
            <span className={"ms-4 ps-1"}>kod pumpe Hifa petrol</span>
          </p>
          <p className={style.locationInfo}>
            <LocationTime />
            <span className={"ms-2"}>Svakog dana 00-24 h</span>
          </p>
        </Popup>
      </Marker>
      <Marker
        position={[43.87895759478183, 18.314732393989015]}
        icon={customIcon}
      >
        <Popup>
          <p className={style.locationName}>Sarajevo</p>
          <p className={style.locationAddress}>
            <LocationPoint />
            <span className={"ms-2 ps-1"}>Zabrđe</span>
          </p>
          <p className={style.locationAddress}>
            <span className={"ms-4 ps-1"}>kod pumpe Hifa petrol</span>
          </p>
          <p className={style.locationInfo}>
            <LocationTime />
            <span className={"ms-2"}>Svakog dana 00-24 h</span>
          </p>
        </Popup>
      </Marker>
      <Marker
        position={[43.85471673692884, 18.327992893249956]}
        icon={customIcon}
      >
        <Popup>
          <p className={style.locationName}>Sarajevo</p>
          <p className={style.locationAddress}>
            <LocationPoint />
            <span className={"ms-2 ps-1"}>Briješće (Safeta Zajke) </span>
          </p>
          <p className={style.locationAddress}>
            <span className={"ms-4 ps-1"}>kod pumpe Petrol</span>
          </p>
          <p className={style.locationInfo}>
            <LocationTime />
            <span className={"ms-2"}>Svakog dana 00-24 h</span>
          </p>
        </Popup>
      </Marker>
      <Marker
        position={[43.852364763588135, 18.413867740929675]}
        icon={customIcon}
      >
        <Popup>
          <p className={style.locationName}>Sarajevo</p>
          <p className={style.locationAddress}>
            <LocationPoint />
            <span className={"ms-2 ps-1"}>Put mladih muslimana Tranzit</span>
          </p>
          <p className={style.locationAddress}>
            <span className={"ms-4 ps-1"}>kod pumpe Petrol</span>
          </p>
          <p className={style.locationInfo}>
            <LocationTime />
            <span className={"ms-2"}>Svakog dana 00-24 h</span>
          </p>
        </Popup>
      </Marker>
      <Marker
        position={[43.835125963436944, 18.339226605662187]}
        icon={customIcon}
      >
        <Popup>
          <p className={style.locationName}>Sarajevo</p>
          <p className={style.locationAddress}>
            <LocationPoint />
            <span className={"ms-2 ps-1"}>Dobrinja</span>
          </p>
          <p className={style.locationAddress}>
            <span className={"ms-4 ps-1"}>kod pumpe Petrol</span>
          </p>
          <p className={style.locationInfo}>
            <LocationTime />
            <span className={"ms-2"}>Svakog dana 00-24 h</span>
          </p>
        </Popup>
      </Marker>
      <Marker
        position={[45.143606211421265, 17.25511843581963]}
        icon={customIcon}
      >
        <Popup>
          <p className={style.locationName}>Gradiška</p>
          <p className={style.locationAddress}>
            <LocationPoint />
            <span className={"ms-2 ps-1"}>Vuka Karadžića 2</span>
          </p>
          <p className={style.locationAddress}>
            <span className={"ms-4 ps-1"}>kod pumpe Nestro petrol</span>
          </p>
          <p className={style.locationInfo}>
            <LocationTime />
            <span className={"ms-2"}>Svakog dana 00-24 h</span>
          </p>
        </Popup>
      </Marker>
    </MapContainer>
  );
}
